import moment from '~/utils/moment';
import { getCurrentInstance } from 'vue';

export default async (locale: string) => {
    const translation = useTranslation();
    translation.setCurrentLocale(locale);
    localStorage.setItem('locale', locale);
    moment.locale(useGetCurrentMomentLocale());

    useIntercomUpdate({
        language_override: useGetCurrentMomentLocale(),
    });
};
