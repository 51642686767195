<script setup lang="ts">
    const emit = defineEmits(['localeChanged']);
    const localeSubmenu = ref(null);
    const translation = useTranslation();
    const availableLocales = useAvailableLocalesByCountry();

    const props = defineProps({
  submenuPosition: { default: 'right-0' }
});

    const changeLocale = (locale: string) => {
        useSetLocale(locale);
        localeSubmenu.value?.hide();
        emit('localeChanged', locale);
    };
</script>

<template>
    <Submenu
        :position="submenuPosition"
        ref="localeSubmenu"
    >
        <template v-slot:default="slotProps">
            <slot
                v-bind:hide="slotProps.hide"
                v-bind:show="slotProps.show"
                v-bind:toggle="slotProps.toggle"
                v-bind:isDisplayed="slotProps.isDisplayed"
            ></slot>
        </template>
        <template v-slot:submenu>
            <div class="w-full min-w-[260px]">
                <div :class="$theme('submenu.items_container')">
                    <div
                        :class="$theme('submenu.items')"
                        v-for="(locales, country) in availableLocales"
                        :key="`language-switcher-country-${country}`"
                    >
                        <a
                            href="#"
                            :class="$theme('submenu.item_without_icon', 'flex items-center justify-between')"
                            v-for="availableLocale in locales"
                            :key="availableLocale.locale"
                            @click.prevent="changeLocale(availableLocale.locale)"
                        >
                            <div class="flex items-center space-x-2">
                                <img
                                    class="h-[16px] bg-neutral-100 min-w-[18px]"
                                    :src="`https://trustup-io-shared-assets.ams3.cdn.digitaloceanspaces.com/images/translations/flags/${country}.svg`"
                                    alt=""
                                />
                                <span>
                                    {{ $t(`locales.native.${availableLocale.language}`) }}
                                </span>
                            </div>
                            <span>
                                <i
                                    class="text-sm fa-regular fa-circle-check"
                                    :class="$theme('submenu.item_icon')"
                                    v-if="availableLocale.locale === translation.getCurrentLocale()"
                                />
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
